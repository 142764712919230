import { HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiService } from "./api.service";
import { CookieService } from 'ngx-cookie-service';
import { AuthenticationResponse } from "../models/authenticationResponse";
import { AuthenticationStatusEnum } from "../models/authenticationStatusEnum";
import { AppSetting } from "./appSetting";

@Injectable({providedIn:'root'})
export class AuthenticationService {
    private _companyLogo: string;
  constructor(private apiService: ApiService, private appSetting: AppSetting, private cookieService: CookieService) { }

    get companyLogo(): string {
        return this._companyLogo;
    }

    public async verifyAccountStatus(data: object, successCallback: (body: object) => void, errorCallback: (errorMesage: string) => void) {
        const registration = await this.apiService.verifyAccountStatus(data)
        registration.subscribe(
            (response) => {
              if (!response.body.accountExists) {
                errorCallback("User not found");
                return;
              }
              successCallback(response.body);
            },
            (error: HttpErrorResponse) => {
              const message = 'An error occurred while verifying account status';
                console.warn(message, error);
                errorCallback(message);
            });
    }

    public async activateUser(data: object, successCallback: (body: object) => void, errorCallback: (errorMesage: string) => void) {
      const activation = await this.apiService.activateUser(data)
      activation.subscribe(
          (response) => {
            if (response.body.errorMessage) {
              errorCallback(response.body.errorMessage === "User is not valid." ? "Security answer is incorrect" : response.body.errorMessage);
              return;
            }
            if (response.body.authentication && response.body.authentication.leadSourceId) {
              localStorage.setItem('settings-app-lsid', response.body.authentication.leadSourceId.toString());
            }
            successCallback(response.body)
          },
          (error: HttpErrorResponse) => {
            const message = 'An error occurred while activating a new user';
              console.warn(message, error);
              errorCallback(message);
          });
  }

  public authenticateAndGrantOAuthToken(username: string, password: string, successCallback: () => void, errorCallback: (errorMesage: string) => void)
  {
      this.apiService.authenticateAndGrantOAuthToken(username, password).subscribe(
          (data) => {
              this.processAuthResponse(data.body.response, successCallback, errorCallback);
        },
          (error: HttpErrorResponse) => {
            const message = 'An error occurred during authentication';
            console.warn(message, error);
            errorCallback(message);
      });
  }

  public grantOauthTokenForConsumer(username: string, password: string, successCallback: () => void, errorCallback: (errorMesage: string, statusCode?: number) => void)
  {
      this.apiService.grantOauthTokenForConsumer(username, password).subscribe(
          (data) => {
              this.processAuthResponse(data.body.response, successCallback, errorCallback);
        },
          (error: HttpErrorResponse) => {
            console.warn(`An error occurred during authentication`, error);
            errorCallback('An error occurred during authentication');
      });
  }

  public authenticateWithSSOToken() {
    this.apiService.authenticateWithSSSToken().subscribe(
      (data) => {
        this.handleAuthResponse(data);
        //if (data.body && data.body.response) {
        //  this.handleAuthResponseBody(data.body.response);

        //}
      },
      (error: HttpErrorResponse) => {

      });
  }

  public handleAuthResponse(data: HttpResponse<any>): void {
    // Store the auth token
    const token = data.headers.get('authorization');
    if (token) {
      this.appSetting.token = token;
      this.appSetting.userAccountId =
        (data.body.response.authentication.accountIds &&
          data.body.response.authentication.accountIds.length &&
          data.body.response.authentication.accountIds[0] &&
          data.body.response.authentication.accountIds[0].toString &&
          data.body.response.authentication.accountIds[0].toString()) ||
        null;

    } else {
      console.log('Missing Oauth token in the response header', data.url, data.body, data.status, data.headers);
    }
  }

  ///**
  // * Used by both login and register to handle an authenticated response
  // * @param response
  // */
  //public handleAuthResponseBody(response: IAuthenticationResponseViewModel): void {
  //  // console.log('handleAuthResponseBody', this.settings.userId);
  //  if (!(response && response.authentication)) {
  //    console.error(`AUTH ENDPOINT DID NOT RETURN AUTHENTICATION DATA`);
  //  }
  //  // Store the loan ID when first logging in
  //  if (!this.settings.loanId) {
  //    this.settings.loanId = response.authentication.loanId || null;
  //  }
  //  // Store the user ID when first logging in
  //  this.settings.userId =
  //    (response.authentication.accountIds &&
  //      response.authentication.accountIds.length &&
  //      response.authentication.accountIds[0] &&
  //      response.authentication.accountIds[0].toString &&
  //      response.authentication.accountIds[0].toString()) ||
  //    null;
  //  // Store the userName when first logging in
  //  this.settings.userName = response.authentication.userAccountName || null;
  //  this.settings.userFullName = response.authentication.firstName + ' ' + response.authentication.lastName || null;
  //  this.settings.ssoUser = response.authentication.isSSOUser || false;

  //  this.analytics.mixpanelSuperProps({ 'Loan Id': this.settings.loanId });
  //  this.analytics.mixpanelSuperProps({ 'CV Guid': this.settings.loanId });
  //  this.keepAlive();
  //  this.sessionExpired = false;

  //  // Clear the opened loans session cookie in case the browser (e.g., Chrome, Edge) settings have a
  //  // pick-up-where-you-left-off option that keeps cookies accross sesions.
  //  this.clearOpenedLoanIdsCookie();
  //}

    public checkIfUserIsLoggedIn(){
        //Check mml-auth-token and mml-profile
        var mmlProfile: AuthenticationResponse = null;
        var mmlAuthToken = this.cookieService.get('mml-auth-token');
        var value = localStorage.getItem('mmlProfile');
        if (value) {
            mmlProfile = JSON.parse(value);
        }
        return !!mmlAuthToken && !!mmlProfile && mmlProfile.succeeded;
    }

    public clearCookie(cookieName: string) {
      this.cookieService.delete(cookieName);
    }

    private processAuthResponse(response: AuthenticationResponse, successCallback: () => void, errorCallback: (errorMesage: string) => void) {
        if (response.succeeded) {
            // Set the Token stored in App Settings to null since it will not be sued when authenticating LO
            this.appSetting.token = null;
            // Add to local storage
            localStorage.setItem('mmlProfile', JSON.stringify(response));
            localStorage.setItem('mmlProfileTC', (new Date()).toString());
            localStorage.setItem('mml-auth-token', response.token);
            if (response.authentication && response.authentication.leadSourceId) {
              localStorage.setItem('settings-app-lsid', response.authentication.leadSourceId.toString());
            }
            //cookie expires in 30 minutes
            const cookieExpirationDate = new Date();
            cookieExpirationDate.setMinutes(cookieExpirationDate.getMinutes() + 30);
            this.cookieService.set('mml-auth-token', response.token, cookieExpirationDate, '', '', false, 'Lax');
            successCallback();
        }
        else if (response.authenticationStatus == AuthenticationStatusEnum.PasswordExpired || response.authenticationStatus == AuthenticationStatusEnum.ResetPasswordOnNextLogin){
            errorCallback('Password must be changed!');
        }
        else if (response.authenticationStatus == AuthenticationStatusEnum.AccountLocked){
            errorCallback('User account is locked !');
        }
        else {
            errorCallback('Whoops, the Email or Password is incorrect');
        }
    }

    public getCompanyLogo() {
        this.apiService.getCompanyLogo().subscribe(
            (response) => {
                let imageType = null;
                    switch (response[0]) {
                        case '/':
                            imageType = 'jpg';
                            break;
                        case 'i':
                            imageType = 'png';
                            break;
                        case 'R':
                            imageType = 'gif';
                            break;
                        case 'P':
                            imageType = 'svg+xml'
                            break;
                    }
                    this._companyLogo =  'data:image/' + imageType + ';base64,' + String(response);
          },
            (error: HttpErrorResponse) => {
              console.warn(`An error occurred while getting the company logo.`, error);
        });
    }
}
