import { AbstractControl, FormControl, FormGroup, FormGroupDirective, NgForm, ValidatorFn } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

/**
 * Form Group Validators
 */
export class AuthorizationFormValidators {
  static samePasswords(group: FormGroup | AbstractControl) {
    const password = group.get('password').value;
    const passwordConfirm = group.get('confirmPassword').value;

    // If both are blank or both are equal, do not show an error
    return (!password && !passwordConfirm) || password === passwordConfirm ? null : { notSamePassword: true };
  }
  static oneLowercase(control: FormControl) {
    return !control.value || AuthorizationFormUtils.hasLowercase(control.value) ? null : { oneLowercase: true };
  }
  static oneUppercase(control: FormControl) {
    return !control.value || AuthorizationFormUtils.hasUppercase(control.value) ? null : { oneUppercase: true };
  }
  static oneDigit(control: FormControl) {
    return !control.value || AuthorizationFormUtils.hasDigit(control.value) ? null : { oneDigit: true };
  }
  static specialCharacters(control: FormControl) {
    return !control.value || AuthorizationFormUtils.hasSpecialCharacter(control.value) ? null : { specialCharacters: true };
  }
  static eightCharacters(control: FormControl) {
    return !control.value || AuthorizationFormUtils.hasEightCharacters(control.value) ? null : { specialCharacters: true };
  }
  static doesNotEqual(comparisonString: string): ValidatorFn {
    return (control: AbstractControl) => {
      return control.value === comparisonString ? { exactMatchNotAllowed: true } : null;
    };
  }
  static validEmail(control: FormControl) {
    if (!control.value) {
      return null;
    }
    return AuthorizationFormUtils.isValidEmail(control.value) ? null : { validEmail: true };
  }
}

/**
 * Reusable tests can be used in validators or in other Angular components
 */
export class AuthorizationFormUtils {
  static hasLowercase(value: string) {
    return /[a-z]/.test(value);
  }
  static hasUppercase(value: string) {
    return /[A-Z]/.test(value);
  }
  static hasDigit(value: string) {
    return /[0-9]/.test(value);
  }
  static hasSpecialCharacter(value: string) {
    return /[!@#\$%\^\&*\)\(+=._-]/.test(value);
  }
  static hasEightCharacters(value: string) {
    return value && value.length && value.length >= 8;
  }
  static isValidEmail(value: string) {
    // Note there is no perfect email regex, hence the 99.99%. Taken from https://emailregex.com/
    // The same expression is used in OPOS...
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value);
  }
}




/**
 * Error state matchers for Material Input fields
 */

export class RepeatPasswordErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return (
      control
      && (control.invalid || control.parent.get('password').value !== control.value)
      && (control.touched || (form && form.submitted))
    );
  }
}

